import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import "../styles/global.css"
import Hoverimage from "../components/Hoverimage"
import { getImage } from "gatsby-plugin-image"

const pageQuery = graphql`
  query Startpage {
    allGraphCmsStartpage(sort: {fields: order_id, order: DESC}) {
      nodes {
        heading
        order_id
        remoteId
        target
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
export default function Home({ data }) {
  const {
    allGraphCmsStartpage: { nodes: categories },
  } = useStaticQuery(pageQuery)
  return (
    <Layout
      title="Katharina Kohler"
      description="Katharina Kohler - M.Sc. psychologist and artist, architectural psychology, digital and analog art, Berlin, Germany"
    >
      <div className="flex justify-center flex-wrap">
        {categories.map(({ heading, target, remoteId, ...category }) => (
          <div className="p-2 uppercase">
            <Hoverimage
              key={remoteId}
              title={heading}
              image={getImage(category.image.localFile)}
              to={target}
              className="uppercase"
            />
          </div>
        ))}
      </div>
    </Layout>
  )
}
